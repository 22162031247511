<template>
  <div class="apply-wrapper">
    <el-card class="st-card">
      <h3>申请列表</h3>

      <!-- 搜索模块 -->
      <div class="st-search">
        <el-form :inline="true" :model="searchForm">
          <el-form-item label="申报状态：">
            <el-select
              v-model="searchForm.status"
              placeholder="请选择">
              <el-option
                v-for="item in applyStatus"
                :key="item.type"
                :label="item.name"
                :value="item.type">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getApplyItems">查询</el-button>
            <el-button>清空</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 表格 -->
      <el-table
        :data="applyItems"
        border
        class="st-table-cols"
        style="width: 100%">
        <el-table-column
          prop="status"
          label="状态"
          width="100">
          <template slot-scope="scope">
            <span
              class="status-text"
              :class="'status--' + statusFil(scope.row.status).type">
              {{ statusFil(scope.row.status).name || '' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="contact"
          label="联系人信息">
          <template slot-scope="scope">
            <p>
              公司名称：
              <span>{{ scope.row.contact.enterpriseName }}</span>
            </p>
            <p>
              联系人：
              <span>{{ scope.row.contact.name }}</span>
            </p>
            <p class="item-interval">
              联系方式：
              <span>{{ scope.row.contact.mobile }}</span>
              <span>{{ scope.row.contact.email }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="enterpriseInfo"
          label="营业执照信息">
          <template slot-scope="scope">
            <p>
              名称：
              <span>{{ scope.row.enterpriseInfo.name }}</span>
            </p>
            <p>
              社会代码：
              <span>{{ scope.row.enterpriseInfo.tax }}</span>
            </p>
            <p>
              法人：
              <span>{{ scope.row.ownerIdCardInfo.name }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="shopInfo"
          label="店铺信息">
          <template slot-scope="scope">
            <p>
              店铺名称：
              <span>{{ scope.row.shopInfo.name }}</span>
            </p>
            <p>
              店铺地址：
              <span>{{ scope.row.shopInfo.address.province }}</span>
              <span>{{ scope.row.shopInfo.address.city }}</span>
              <span>{{ scope.row.shopInfo.address.region }}</span>
              <span>{{ scope.row.shopInfo.address.street }}</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="itemDetail(scope.row)">
              详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="st-pagination">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          background
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>

    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: '',
  data () {
    return {
      applyStatus: [
        {
          type: null,
          name: '全部'
        },
        {
          type: 0,
          name: '待审核'
        },
        {
          type: -1,
          name: '已拒绝'
        },
        {
          type: 1,
          name: '已通过'
        }
      ],

      currentPage: 1,
      total: 0,

      searchForm: {},
      applyItems: []
    }
  },
  mounted () {
    this.getApplyItems()
  },
  methods: {
    // 状态过滤
    statusFil (val) {
      if (val === -1)
        return {
          name: '已拒绝',
          type: 'danger'
        }
      if (val === 0)
        return {
          name: '待审核',
          type: 'primary'
        }
      if (val === 1)
        return {
          name: '已通过',
          type: 'success'
        }
    },

    // 切换页码
    handleCurrentChange(val) {
      this.currentPage = val
      this.getApplyItems()
    },

    // 获取申报列表
    getApplyItems () {
      this.$api.apply.items(_.assign(
        this.searchForm,
        {
          page: this.currentPage,
          limit: 10
        }
      )).then(res => {
        if (res.data.success) {
          this.total = res.data.result.total
          this.applyItems = res.data.result.data
        }
      })
    },

    // 申报详情
    itemDetail (item) {
      this.$router.push({
        name: 'applyDetails',
        query: {
          id: item.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../../../assets/css/base";
</style>
